import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Session from "@/modules/session";
import Notificator from "@/modules/notificator";
import Home from "../views/Home.vue";
import settings from "./settings";
import auth from "./auth";
import branch from "./branch";
import user from "./user";
import workArea from "./workArea";
import employee from "./employee";
import document from "./document";
import plain from "./plain";
import subscription from "./subscription";

let uriRoutes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/loading",
    name: "Loader",
    component: () => import("../views/Loader.vue")
  },
  {
    path: "/welcome",
    name: "Welcome",
    component: () => import("../views/Welcome.vue")
  },
  {
    path: "/:catchAll(.*)",
    name: "Redirect Not Found",
    redirect: "/404"
  },
  {
    path: "/404",
    name: "404",
    component: () => import("../views/NotFound.vue")
  },
  {
    path: "/playground",
    name: "Playground",
    component: () => import("../views/playground.vue")
  }
];
uriRoutes = uriRoutes.concat(settings);
uriRoutes = uriRoutes.concat(auth);
uriRoutes = uriRoutes.concat(branch);
uriRoutes = uriRoutes.concat(user);
uriRoutes = uriRoutes.concat(workArea);
uriRoutes = uriRoutes.concat(employee);
uriRoutes = uriRoutes.concat(document);
uriRoutes = uriRoutes.concat(plain);
uriRoutes = uriRoutes.concat(subscription);

const routes: Array<RouteRecordRaw> = uriRoutes;

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const publicPages = [
    "/login",
    "/forgotPassword",
    "/signup",
    "/signout",
    "/sendEmail",
    "/resetPassword",
    "/404"
  ];

  let authRequired = !publicPages.includes(to.path);
  if (to.path.includes("changePassword")) authRequired = false;
  const loggedIn = localStorage.getItem("TokenEXP");

  if (authRequired && !loggedIn) {
    return next("/login");
  } else if (authRequired) {
    const checkSession = new Session().check();
    if (Notification.permission !== "denied") {
      Notification.requestPermission();
    }
  }

  next();
});

export default router;
