<template>
  <div>
    <small class="position-absolute bottom-0 start-0 my-2 mx-4 text-center"
      >Software Desarrollado por
      <a rel="noreferrer" target="_blank" href="https://assoft.co"
        >Assoft Tecnología, Innovación y Desarrollo SAS</a
      >
      | {{ new Date().getFullYear() }} © Todos los derechos reservados</small
    >
    <div id="warningSubscription" v-if="false">
      <p>
        Actualmente posee una versión de prueba. Debe adquirir un plan para
        continuar utilizando el aplicativo. Puede hacerlo dando
        <router-link to="/subscription">click aquí</router-link>. Su periodo de
        prueba termina en <strong>{{ restDays }}</strong> días.
      </p>
    </div>
  </div>
</template>

<script>
import Storage from "@/modules/storage";
export default {
  name: "Legal",
  data() {
    return {
      freeSubscription: false,
      restDays: 0
    };
  },
  async mounted() {
    const store = new Storage();
    const planData = await store.getData(
      "plan",
      localStorage.getItem("subscription.plan")
    );
    const planName = planData.name;
    const subExp = localStorage.getItem("subscription.end");

    if (planName == "Free") {
      this.freeSubscription = true;
      this.restDays = Math.round((subExp - +new Date()) / 86400000);
    }
  }
};
</script>

<style lang="css">
#warningSubscription {
  height: 150px;
  background-color: var(--bs-danger);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
#warningSubscription p {
  color: white;
  font-size: 1.3em;
  text-align: center;
}
#warningSubscription a {
  color: black;
  text-decoration: underline;
}
</style>
