<template>
  <div class="col-12 rounded advertising card">Publicidad Assoft</div>
</template>

<script>
export default {
  name: "Dashboard Advertising"
};
</script>

<style lang="css">
.advertising {
  background-color: var(--secondary-bg);
  height: 150px;
}
</style>
