export default [
  {
    path: "/employee/profile",
    name: "Employee Profile",
    component: () => import("../views/employee/Profile.vue")
  },
  {
    path: "/employee/profile/:_id",
    name: "Employee Profile Branch",
    component: () => import("../views/employee/ProfileBranch.vue")
  },
  {
    path: "/employee/profile/create/:_id",
    name: "Employee Profile Create",
    component: () => import("../views/employee/Create.vue")
  },
  {
    path: "/employee/profile/edit/basic/:_id",
    name: "Employee Profile Edit",
    component: () => import("../views/employee/edit/Basic.vue")
  },
  {
    path: "/employee/profile/edit/contact/:_id",
    name: "Employee Profile Edit Contact",
    component: () => import("../views/employee/edit/Contact.vue")
  },
  {
    path: "/employee/profile/edit/origin/:_id",
    name: "Employee Profile Edit Origin",
    component: () => import("../views/employee/edit/Origin.vue")
  },
  {
    path: "/employee/profile/edit/emergency/:_id",
    name: "Employee Profile Edit Emergency",
    component: () => import("../views/employee/edit/Emergency.vue")
  },
  {
    path: "/employee/profile/edit/labor/:_id",
    name: "Employee Profile Edit Labor",
    component: () => import("../views/employee/edit/Labor.vue")
  },
  {
    path: "/employee/profile/edit/membership/:_id",
    name: "Employee Profile Edit Membership",
    component: () => import("../views/employee/edit/Membership.vue")
  },
  {
    path: "/employee/profile/edit/additional/:_id",
    name: "Employee Profile Edit Additional",
    component: () => import("../views/employee/edit/Additional.vue")
  },
  {
    path: "/employee/evaluation",
    name: "Employee Evaluation",
    component: () => import("../views/employee/Evaluation.vue")
  },
  {
    path: "/employee/evaluation/:_id",
    name: "Employee Evaluation Branch",
    component: () => import("../views/employee/EvaluationBranch.vue")
  },
  {
    path: "/employee/evaluation/create/:_id",
    name: "Employee Evaluation Create",
    component: () => import("../views/employee/CreateEvaluation.vue")
  },
  {
    path: "/employee/evaluation/edit/:_id",
    name: "Employee Evaluation Edit",
    component: () => import("../views/employee/EditEvaluation.vue")
  },
  {
    path: "/employee/ATEL",
    name: "Employee AT",
    component: () => import("../views/employee/AT.vue")
  },
  {
    path: "/employee/ATEL/:_id",
    name: "Employee AT Branch",
    component: () => import("../views/employee/ATBranch.vue")
  },
  {
    path: "/employee/ATEL/create/:_id",
    name: "Employee AT Create",
    component: () => import("../views/employee/ATSelector.vue")
  },
  {
    path: "/employee/ATEL/create/:_id/accident",
    name: "Employee AT Create Accident",
    component: () => import("../views/employee/accidentCreate.vue")
  },
  {
    path: "/employee/ATEL/edit/:_id/accident/:currentId",
    name: "Employee AT Edit Accident",
    component: () => import("../views/employee/accidentEdit.vue")
  },
  {
    path: "/employee/ATEL/create/:_id/disease",
    name: "Employee EL Create Disease",
    component: () => import("../views/employee/diseaseCreate.vue")
  },
  {
    path: "/employee/ATEL/edit/:_id/disease/:currentId",
    name: "Employee AT Edit Disease",
    component: () => import("../views/employee/diseaseEdit.vue")
  }
];
