import Global from "./globals";
import axios from "axios";

class Background extends Global {
  urlBase64ToUint8Array(base64String: string) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, "+")
      .replace(/_/g, "/");
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }
  subscription(PUBLIC_VAPID_KEY: string) {
    navigator.serviceWorker.register("/service-worker.js").then(register => {
      register.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: this.urlBase64ToUint8Array(PUBLIC_VAPID_KEY)
      });
    });
  }
}

const background = new Background();
let PUBLIC_VAPID_KEY = localStorage.getItem("PUBLIC_VAPID_KEY");

if (PUBLIC_VAPID_KEY === null) {
  axios
    .get(background.baseURL + "/subscription/notification/vapid", {
      withCredentials: true
    })
    .then(response => {
      PUBLIC_VAPID_KEY = response.data.body.publicKey;
      localStorage.setItem("PUBLIC_VAPID_KEY", PUBLIC_VAPID_KEY + "");
      background.subscription(PUBLIC_VAPID_KEY + "");
    });
} else {
  background.subscription(PUBLIC_VAPID_KEY + "");
}
