export default [
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/auth/Login.vue")
  },
  {
    path: "/signup",
    name: "Signup",
    component: () => import("../views/auth/Signup.vue")
  },
  {
    path: "/forgotPassword",
    name: "ForgotPassword",
    component: () => import("../views/auth/ForgotPassword.vue")
  },
  {
    path: "/emailConfirmation/:code",
    name: "EmailConfirmation",
    component: () => import("../views/auth/EmailConfirmation.vue")
  },
  {
    path: "/changePassword/:token",
    name: "ChangePassword",
    component: () => import("../views/auth/ChangePassword.vue")
  }
];
