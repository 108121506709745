<template>
  <div class="main">
    <div class="row mx-4 mt-4 px-2" v-if="advertising">
      <Advertising></Advertising>
    </div>
    <div class="d-flex justify-content-end my-3 mx-4 px-2">
      <select
        class="form-select text-capitalize branchSelect"
        aria-label="Default select example"
        v-model="branch"
      >
        <option :value="null">Todas las Sedes</option>
        <option
          v-for="branch in branchs"
          :key="branch._id"
          :value="branch._id"
          >{{ branch.cityName }}</option
        >
      </select>
    </div>
    <div class="row mx-4 mb-3">
      <div class="col">
        <label for="startDate" class="form-label">Fecha de Inicio</label>
        <input
          type="date"
          class="form-control"
          id="startDate"
          v-model="startDate"
        />
      </div>
      <div class="col">
        <label for="lastDate" class="form-label">Fecha Final</label>
        <input
          type="date"
          class="form-control"
          id="lastDate"
          v-model="lastDate"
        />
      </div>
    </div>
    <div class="row mx-4 mb-5">
      <div class="col-xs-12 col-lg-6 col-xxl-4 px-2 mb-3">
        <SSTIndex
          :startDate="startDate"
          :endDate="lastDate"
          :branchId="branch"
          :type="'frecuency'"
        ></SSTIndex>
      </div>
      <div class="col-xs-12 col-lg-6 col-xxl-4 px-2 mb-3">
        <SSTIndex
          :startDate="startDate"
          :endDate="lastDate"
          :branchId="branch"
          :type="'severity'"
        ></SSTIndex>
      </div>
      <div class="col-xs-12 col-lg-6 col-xxl-4 px-2 mb-3">
        <SSTIndex
          :startDate="startDate"
          :endDate="lastDate"
          :branchId="branch"
          :type="'mortality'"
        ></SSTIndex>
      </div>
      <div class="col-xs-12 col-lg-6 col-xxl-4 px-2 mb-3">
        <SSTIndex
          :startDate="startDate"
          :endDate="lastDate"
          :branchId="branch"
          :type="'prevalence'"
        ></SSTIndex>
      </div>
      <div class="col-xs-12 col-lg-6 col-xxl-4 px-2 mb-3">
        <SSTIndex
          :startDate="startDate"
          :endDate="lastDate"
          :branchId="branch"
          :type="'incident'"
        ></SSTIndex>
      </div>
      <div class="col-xs-12 col-lg-6 col-xxl-4 px-2 mb-3">
        <SSTIndex
          :startDate="startDate"
          :endDate="lastDate"
          :branchId="branch"
          :type="'absenteeism'"
        ></SSTIndex>
      </div>
      <div class="col-xs-12 col-lg-7 col-xxl-8 px-2 mb-3">
        <StorageCom></StorageCom>
      </div>
      <div class="col-xs-12 col-lg-5 col-xxl-4 px-2 mb-3">
        <Plain></Plain>
      </div>
      <div class="col-xs-12 col-lg-7 col-xxl-8 px-2 mb-3">
        <Standard></Standard>
      </div>
      <div class="col-xs-12 col-lg-5 col-xxl-4 px-2 mb-3">
        <Employee></Employee>
      </div>
    </div>
    <Legal></Legal>
  </div>
</template>

<script>
import Legal from "@/components/layout/Legal";
import Advertising from "@/components/dashboard/Advertising";
import StorageCom from "@/components/dashboard/Storage.vue";
import Plain from "@/components/dashboard/Plain.vue";
import Standard from "@/components/dashboard/Standard.vue";
import Employee from "@/components/dashboard/Employee.vue";
import SSTIndex from "@/components/dashboard/SSTIndex.vue";

import Storage from "@/modules/storage";

export default {
  name: "Home",
  data() {
    return {
      branch: null,
      branchs: [],
      advertising: false,
      startDate: "",
      lastDate: ""
    };
  },
  beforeCreate() {
    localStorage.setItem("currentLocation", "Dashboard");
  },
  async mounted() {
    const d = new Date();
    const year = d.getFullYear().toString();
    let month = d.getMonth() + 1;
    let day = d.getDate();
    if (month < 10) {
      month = "0" + month.toString();
    } else {
      month = month.toString();
    }
    if (day < 10) {
      day = "0" + day.toString();
    } else {
      day = day.toString();
    }
    const localStart = localStorage.getItem("dashboardStartDate");
    const localEnd = localStorage.getItem("dashboardEndDate");
    if (localStart) {
      this.startDate = localStart;
    } else {
      this.startDate = year + "-01-01";
      localStorage.setItem("dashboardStartDate", this.startDate);
    }
    if (localEnd) {
      this.lastDate = localEnd;
    } else {
      this.lastDate = year + "-" + month + "-" + day;
      localStorage.setItem("dashboardEndDate", this.lastDate);
    }
    const store = new Storage();
    const planData = await store.getData(
      "plan",
      localStorage.getItem("subscription.plan")
    );
    this.advertising = planData.restrictions.advertising;
    store.getData("branch").then(data => {
      this.branchs = data;
      data.forEach((branch, index) => {
        store.getData("city", branch.city).then(city => {
          this.branchs[index].cityName = city.name;
        });
      });
    });
  },
  components: {
    Legal,
    Advertising,
    StorageCom,
    Plain,
    Standard,
    Employee,
    SSTIndex
  }
};
</script>

<style lang="css">
.branchSelect {
  width: 300px;
}
</style>
