<template>
  <Navigation></Navigation>
  <Header></Header>
  <router-view />
</template>

<style lang="scss">
@import "scss/main.scss";
</style>

<script>
import "@/modules/backgroundSync";

import Navigation from "@/components/layout/Navigation";
import Header from "@/components/layout/Header";

export default {
  components: {
    Navigation,
    Header
  }
};
</script>
