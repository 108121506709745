import Storage from "@/modules/storage";

class SST {
  branchID: any;
  startDate: number;
  endDate: number;
  storage: any;
  employee: any;
  accident: any;
  frecuencyStandard: number;
  workHours: number;
  weeksPerYear: number;
  daysPerMonth: number;
  days: number;
  monthsPerYear: number;
  daysPerDeath: number;

  constructor(branchID = null, startDate = 0, endDate = 0) {
    this.branchID = branchID;
    this.storage = new Storage();
    this.frecuencyStandard = 5000; // 200 000 horas son 5 000 días
    this.workHours = 48;
    this.weeksPerYear = 47;
    this.daysPerMonth = 30;
    this.monthsPerYear = 12;
    this.daysPerDeath = 6000;

    this.startDate = startDate;
    this.endDate = endDate;

    if (startDate === 0) {
      const date = new Date();
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      this.startDate = firstDay.getTime();
    }
    if (endDate === 0) {
      const date = new Date();
      const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      this.endDate = lastDay.getTime();
    }
    this.days = (this.endDate - this.startDate) / 86400000;
  }

  setLastMonth() {
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
    this.startDate = firstDay.getTime();
    this.endDate = lastDay.getTime();
  }

  async getEmployee() {
    const employees = await this.storage.getData("employee");
    this.employee = [];
    if (this.branchID) {
      employees.forEach((employee: any) => {
        if (
          employee.branch == this.branchID &&
          employee.startAtWork < this.endDate
        ) {
          this.employee.push(employee);
        }
      });
    } else {
      this.employee = employees;
    }
  }
  async getAccident() {
    const accidents = await this.storage.getData("accident");
    const prevAccident: any = [];
    this.accident = [];
    accidents.forEach((accident: any) => {
      if (
        accident.accidentDate > this.startDate &&
        accident.accidentDate < this.endDate
      ) {
        prevAccident.push(accident);
      }
    });
    if (this.branchID) {
      prevAccident.forEach((accident: any) => {
        if (
          accident.branch == this.branchID &&
          accident.accidentDate > this.startDate &&
          accident.accidentDate < this.endDate
        ) {
          this.accident.push(accident);
        }
      });
    } else {
      this.accident = prevAccident;
    }
  }

  async prevalence() {
    // Falta calculo de enfermedad laboral

    await this.getEmployee();
    await this.getAccident();

    const employeeCount = this.employee.length;
    const accidentCount = this.accident.length;

    let total = accidentCount / employeeCount;
    total *= 100;
    if (!isFinite(total)) {
      total = 0;
    }

    return total;
  }

  async incident() {
    // Falta calculo de enfermedad laboral

    await this.getEmployee();
    await this.getAccident();

    const employeeCount = this.employee.length;
    const accidentCount = this.accident.length;

    let total = accidentCount / employeeCount;
    total *= this.frecuencyStandard;
    if (!isFinite(total)) {
      total = 0;
    }

    return total;
  }

  async mortality() {
    // Falta calculo de enfermedad laboral

    await this.getAccident();

    const accidentCount = this.accident.length;

    let deaths = 0;
    this.accident.forEach((anyAccident: any) => {
      if (anyAccident.causedDeath) {
        deaths++;
      }
    });

    let total = deaths / accidentCount;
    total *= 100;
    if (!isFinite(total)) {
      total = 0;
    }

    return total;
  }

  async severity() {
    // Falta calculo de enfermedad laboral

    await this.getEmployee();
    await this.getAccident();

    const employeeCount = this.employee.length;
    let loadedDays = 0;

    let daysOfDisability = 0;
    let deaths = 0;
    let loadedPCL = 0;
    this.accident.forEach((anyAccident: any) => {
      daysOfDisability += anyAccident.daysOfDisability;
      loadedPCL += anyAccident.PCL;
      if (anyAccident.causedDeath) {
        deaths++;
      }
    });
    deaths *= this.daysPerDeath;
    loadedPCL /= 100;
    loadedPCL *= this.daysPerDeath;
    loadedDays = deaths + loadedPCL;
    loadedDays += daysOfDisability;

    let total = loadedDays / employeeCount;
    total *= 100;
    if (!isFinite(total)) {
      total = 0;
    }

    return total;
  }

  async frecuency() {
    // Falta calculo de enfermedad laboral

    await this.getEmployee();
    await this.getAccident();

    const employeeCount = this.employee.length;
    const accidentCount = this.accident.length;
    let workedDays = employeeCount * this.days;

    let daysOfDisability = 0;
    this.accident.forEach((anyAccident: any) => {
      daysOfDisability += anyAccident.daysOfDisability;
    });

    workedDays -= daysOfDisability;
    workedDays = Math.abs(workedDays);

    let total = accidentCount / workedDays;
    total *= this.frecuencyStandard;
    if (!isFinite(total)) {
      total = 0;
    }

    return total;
  }

  async absenteeism() {
    // Falta calculo de enfermedad laboral

    await this.getEmployee();
    await this.getAccident();

    const employeeCount = this.employee.length;
    const workedDays = employeeCount * this.days;

    let daysOfDisability = 0;
    this.accident.forEach((anyAccident: any) => {
      daysOfDisability += anyAccident.daysOfDisability;
    });

    let total = daysOfDisability / workedDays;
    total *= 100;
    if (!isFinite(total)) {
      total = 0;
    }
    return total;
  }
}

export default SST;
