export default [
  {
    path: "/subscription",
    name: "Subscription",
    component: () => import("../views/subscription/Index.vue")
  },
  {
    path: "/subscription/accepted",
    name: "Subscription Accepted",
    component: () => import("../views/subscription/Accepted.vue")
  }
];
