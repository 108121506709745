export default [
  {
    path: "/settings/basic",
    name: "Settings Basic",
    component: () => import("../views/settings/SettingBasic.vue")
  },
  {
    path: "/settings/legal",
    name: "Settings Legal",
    component: () => import("../views/settings/SettingLegal.vue")
  },
  {
    path: "/settings/password",
    name: "Settings Password",
    component: () => import("../views/settings/SettingPassword.vue")
  },
  {
    path: "/settings/danger",
    name: "Settings Danger",
    component: () => import("../views/settings/SettingDanger.vue")
  }
];
