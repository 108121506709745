import Global from "./globals";
import Swal from "sweetalert2";

class Notificator extends Global {
  mainMsg(msg: string, severity?: number) {
    if (severity == 1) {
      Swal.fire({
        icon: "info",
        title: msg,
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        }
      });
    } else if (severity == 2) {
      Swal.fire({
        icon: "warning",
        title: msg,
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        }
      });
    } else if (severity == 3) {
      Swal.fire({
        icon: "error",
        title: msg,
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        }
      });
    } else if (severity == 4) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: msg
      });
    } else if (severity == 5) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: msg,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        stopKeydownPropagation: true
      });
    }
  }
}

const Notify = new Notificator();

export default Notify;
