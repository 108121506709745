<template>
  <div v-if="!hide" id="Nav">
    <ul class="nav flex-column position-fixed bottom-0 start-0 pt-4">
      <router-link class="d-grid gap-2" to="/subscription">
        <button type="button" class="btn btn-primary mx-4 mb-3">
          Cambiar Plan
        </button>
      </router-link>
      <li class="nav-item mx-3">
        <router-link class="nav-link p-1" to="/">
          <svg class="bi me-2" fill="currentColor">
            <use xlink:href="/img/icons/bootstrap-icons.svg#pie-chart-fill" />
          </svg>
          Dashboard
        </router-link>
      </li>
      <li class="nav-item mx-3">
        <router-link class="nav-link p-1" to="/branch">
          <svg class="bi me-2" fill="currentColor">
            <use xlink:href="/img/icons/bootstrap-icons.svg#briefcase-fill" />
          </svg>
          Sedes
        </router-link>
      </li>
      <!--
      <li class="nav-item mx-3">
        <router-link class="nav-link p-1" to="/user">
          <svg class="bi me-2" fill="currentColor">
            <use xlink:href="/img/icons/bootstrap-icons.svg#person-fill" />
          </svg>
          Usuarios
        </router-link>
      </li>
      -->

      <li class="nav-item mx-3">
        <div
          style="cursor: pointer"
          class="p-1 collapse-list"
          aria-current="page"
          @click.prevent="showSubList('sub2')"
          :class="{ 'router-link-exact-active': subIsActive('/employee') }"
        >
          <svg class="bi me-2" fill="currentColor">
            <use xlink:href="/img/icons/bootstrap-icons.svg#people-fill" />
          </svg>
          Empleados
          <svg class="bi me-2 arrow" fill="currentColor">
            <use xlink:href="/img/icons/bootstrap-icons.svg#caret-right-fill" />
          </svg>
        </div>
        <ul class="collapse-sublist" id="sub2-closed">
          <li>
            <router-link
              to="/employee/profile"
              :class="{
                'router-link-exact-active': subIsActive('/employee/profile')
              }"
              >Perfiles</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              id="navWorkAreas"
              to="/employee/workArea"
              :class="{
                'router-link-exact-active': subIsActive('/employee/workArea')
              }"
            >
              Áreas de Trabajo
            </router-link>
          </li>
          <li>
            <router-link
              to="/employee/evaluation"
              :class="{
                'router-link-exact-active': subIsActive('/employee/evaluation')
              }"
              >Evaluaciones Médicas</router-link
            >
          </li>
          <li>
            <router-link
              to="/employee/ATEL"
              :class="{
                'router-link-exact-active': subIsActive('/employee/ATEL')
              }"
              >Accidentes y Enfermedades</router-link
            >
          </li>
        </ul>
      </li>

      <li class="nav-item mx-3">
        <div
          style="cursor: pointer"
          class="p-1 collapse-list"
          aria-current="page"
          @click.prevent="showSubList('sub1')"
          :class="{ 'router-link-exact-active': subIsActive('/document') }"
        >
          <svg class="bi me-2" fill="currentColor">
            <use xlink:href="/img/icons/bootstrap-icons.svg#file-text-fill" />
          </svg>
          Documentos
          <svg class="bi me-2 arrow" fill="currentColor">
            <use xlink:href="/img/icons/bootstrap-icons.svg#caret-right-fill" />
          </svg>
        </div>
        <ul class="collapse-sublist" id="sub1-closed">
          <li>
            <router-link
              to="/document/main"
              :class="{
                'router-link-exact-active': subIsActive('/document/main')
              }"
              >Carpetas</router-link
            >
          </li>
          <li>
            <router-link
              to="/document/retention"
              :class="{
                'router-link-exact-active': subIsActive('/document/retention')
              }"
              >Retención Documental</router-link
            >
          </li>
          <li>
            <router-link
              to="/document/inactive"
              :class="{
                'router-link-exact-active': subIsActive('/document/inactive')
              }"
              >Archivo Inactivo</router-link
            >
          </li>
        </ul>
      </li>

      <li class="nav-item mx-3">
        <div
          style="cursor: pointer"
          class="p-1 collapse-list"
          aria-current="page"
          @click.prevent="showSubList('sub3')"
          :class="{ 'router-link-exact-active': subIsActive('/plain') }"
        >
          <svg class="bi me-2" fill="currentColor">
            <use
              xlink:href="/img/icons/bootstrap-icons.svg#file-spreadsheet-fill"
            />
          </svg>
          Plan de Trabajo y Mejoramiento
          <svg class="bi me-2 arrow" fill="currentColor">
            <use xlink:href="/img/icons/bootstrap-icons.svg#caret-right-fill" />
          </svg>
        </div>
        <ul class="collapse-sublist" id="sub3-closed">
          <li>
            <router-link
              to="/plain/target"
              :class="{
                'router-link-exact-active': subIsActive('/plain/target')
              }"
              >Objetivos</router-link
            >
          </li>
          <li>
            <router-link
              to="/plain/summary"
              :class="{
                'router-link-exact-active': subIsActive('/plain/summary')
              }"
              >Plan de Trabajo</router-link
            >
          </li>
        </ul>
      </li>
      <li class="nav-item mx-3">
        <a
          class="nav-link p-1"
          href="https://www.who.int/es/emergencies/diseases/novel-coronavirus-2019/advice-for-public/q-a-coronaviruses"
          target="_blank"
        >
          <svg class="bi me-2" fill="currentColor">
            <use
              xlink:href="/img/icons/bootstrap-icons.svg#exclamation-triangle-fill"
            />
          </svg>
          Covid-19
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Navigation",
  methods: {
    showSubList(listId) {
      try {
        document.getElementById(listId + "-closed").style.height = "auto";
        document.getElementById(listId + "-closed").id = listId + "-active";
      } catch (error) {
        document.getElementById(listId + "-active").style.height = "0";
        document.getElementById(listId + "-active").id = listId + "-closed";
      }
    },
    subIsActive(input) {
      const paths = Array.isArray(input) ? input : [input];
      return paths.some(path => {
        return this.$route.path.indexOf(path) === 0; // current path starts with this path string
      });
    }
  },
  computed: {
    hide() {
      if (this.$route.path.includes("/employee/profile/edit")) {
        return false;
      }
      return (
        this.$route.path === "/login" ||
        this.$route.path === "/forgotPassword" ||
        this.$route.path === "/signup" ||
        this.$route.path === "/welcome" ||
        this.$route.path === "/404" ||
        this.$route.path === "/loading" ||
        this.$route.path.includes("edit") ||
        this.$route.path.includes("create") ||
        this.$route.path.includes("temp") ||
        this.$route.path.includes("/plain/summary/report") ||
        this.$route.path.includes("/plain/summary/execute") ||
        this.$route.path.includes("/changePassword/")
      );
    }
  }
};
</script>

<style lang="css" scoped>
#Nav > ul {
  background-color: var(--main-bg);
  width: var(--nav-width);
  height: calc(100% - var(--header-height));
  border-right: 1px solid rgb(206, 206, 206);
  left: var(--nav-position) !important;
  transition: 0.3s ease-in-out;
  flex-wrap: nowrap !important;
  overflow-y: auto;
  z-index: 10;
}
#Nav a {
  color: var(--main-text);
}
#Nav svg {
  height: 16px;
  width: 16px;
}
.collapse-list > a {
  pointer-events: none;
  display: flex;
}
.collapse-list .arrow {
  position: absolute;
  right: 10px;
}
.collapse-sublist {
  height: 0;
  overflow: hidden;
}
</style>
