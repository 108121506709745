<template>
  <div class="rounded card p-3 d-flex flex-row">
    <div class="index-left">
      <p class="fw-bold text-dark">{{ name }}</p>
      <h1 class="fw-bold">{{ Math.round(total * 100) / 100 }}%</h1>

      <div
        v-if="diference > 0"
        class="py-1 px-3 bg-danger text-white rounded-pill card-index"
      >
        <svg class="bi card-icon" fill="currentColor">
          <use xlink:href="/img/icons/bootstrap-icons.svg#caret-up-fill" />
        </svg>
        {{ Math.round(diference * 100) / 100 }}%
      </div>

      <div
        v-else-if="diference < 0"
        class="py-1 px-3 bg-primary text-white rounded-pill card-index"
      >
        <svg class="bi card-icon" fill="currentColor">
          <use xlink:href="/img/icons/bootstrap-icons.svg#caret-down-fill" />
        </svg>
        {{ Math.round(diference * 100) / 100 }}%
      </div>

      <div v-else class="py-1 px-3 bg-info text-white rounded-pill card-index">
        <svg class="bi card-icon" fill="currentColor">
          <use xlink:href="/img/icons/bootstrap-icons.svg#align-center" />
        </svg>
        {{ Math.round(diference * 100) / 100 }}%
      </div>
    </div>
    <div class="d-flex justify-content-center align-items-center index-right">
      <canvas ref="graphCanvas"></canvas>
    </div>
  </div>
</template>

<style lang="css">
.card-icon {
  height: 18px;
  width: 18px;
}
.card-index {
  display: inline;
}
.index-left {
  width: 60%;
}
.index-right {
  width: 40%;
}
</style>

<script>
import Chart from "chart.js";
import SST from "@/modules/SST";

export default {
  props: {
    type: {
      type: String
    },
    branchId: {
      type: String,
      default: null
    },
    startDate: {
      type: String,
      default: null
    },
    endDate: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      name: "",
      total: null,
      prev: null,
      diference: null,
      start: 0,
      end: 0
    };
  },
  mounted() {
    this.calculated();
  },
  methods: {
    async calculated() {
      const sst = new SST(this.branchId, this.start, this.end);

      if (this.type == "frecuency") {
        this.name = "Índice de Frecuencia";
        this.total = await sst.frecuency();
        sst.setLastMonth();
        this.prev = await sst.frecuency();
      } else if (this.type == "severity") {
        this.name = "Índice de Severidad";
        this.total = await sst.severity();
        sst.setLastMonth();
        this.prev = await sst.severity();
      } else if (this.type == "mortality") {
        this.name = "Índice de Mortalidad";
        this.total = await sst.mortality();
        sst.setLastMonth();
        this.prev = await sst.mortality();
      } else if (this.type == "prevalence") {
        this.name = "Índice de Prevalencia";
        this.total = await sst.prevalence();
        sst.setLastMonth();
        this.prev = await sst.prevalence();
      } else if (this.type == "incident") {
        this.name = "Índice de Incidentes";
        this.total = await sst.incident();
        sst.setLastMonth();
        this.prev = await sst.incident();
      } else if (this.type == "absenteeism") {
        this.name = "Índice de Ausentismo";
        this.total = await sst.absenteeism();
        sst.setLastMonth();
        this.prev = await sst.absenteeism();
      }
      this.diference = this.total - this.prev;

      const ctx = this.$refs.graphCanvas.getContext("2d");
      const gradient = ctx.createLinearGradient(0, 0, 0, 250);
      let color;
      if (this.diference > 0) {
        gradient.addColorStop(0, "rgba(220, 53, 69, 0.3)");
        gradient.addColorStop(0.5, "rgba(220, 53, 69, 0)");
        color = "rgba(220, 53, 69, 1)";
      } else if (this.diference < 0) {
        gradient.addColorStop(0, "rgba(27, 85, 226, 0.3)");
        gradient.addColorStop(0.5, "rgba(27, 85, 226, 0)");
        color = "rgba(27, 85, 226, 1)";
      } else {
        gradient.addColorStop(0, "rgba(108, 117, 125, 0.3)");
        gradient.addColorStop(0.5, "rgba(108, 117, 125, 0)");
        color = "rgba(108, 117, 125, 1)";
      }
      const myChart = new Chart(ctx, {
        type: "line",
        data: {
          labels: ["Marzo", "Abril"],
          datasets: [
            {
              data: [
                Math.round(this.prev),
                Math.round(this.total),
                Math.round(this.prev),
                Math.round(this.total) + 1
              ],
              borderColor: color,
              backgroundColor: gradient,
              borderWidth: 3,
              tension: 0.5
            }
          ]
        },
        options: {
          responsive: true,
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false
                },
                ticks: {
                  display: false
                }
              }
            ],
            yAxes: [
              {
                gridLines: {
                  display: false
                },
                ticks: {
                  display: false
                }
              }
            ]
          },
          legend: {
            display: false
          }
        }
      });
    }
  },
  watch: {
    branchId: function() {
      this.calculated();
    },
    startDate: function() {
      localStorage.setItem("dashboardStartDate", this.startDate);
      let tempDate = this.startDate.split("-");
      tempDate = new Date(tempDate[0], tempDate[1] - 1, tempDate[2]);
      this.start = tempDate.getTime();
      this.calculated();
    },
    endDate: function() {
      localStorage.setItem("dashboardEndDate", this.endDate);
      let tempDate = this.endDate.split("-");
      tempDate = new Date(tempDate[0], tempDate[1] - 1, tempDate[2]);
      this.end = tempDate.getTime();
      this.calculated();
    }
  }
};
</script>
