class Global {
  baseURL: string;
  staticURL: string;
  endpointName: string;

  constructor() {
    this.baseURL = "";
    if (window.location.hostname === "localhost") {
      this.baseURL = "http://localhost:5001";
    } else if (window.location.hostname === "optima.assoft.co") {
      this.baseURL = "https://v1.engine.assoft.co";
    } else if (window.location.hostname === "optima-test.assoft.co") {
      this.baseURL = "https://v1-test.engine.assoft.co";
    }
    this.staticURL = "https://storage.assoft.co";
    this.endpointName = "optima";
  }
}

export default Global;
