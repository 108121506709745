import Notify from "@/modules/notificator v2";
import Global from "./globals";
import axios from "axios";
import { IDGenerator } from "./tools v2";

interface IndexedDB {
  open: any;
  deleteDatabase: any;
}

class DB extends Global {
  indexedDB: IndexedDB;
  db: any;
  dbName: string;
  dbVersion: number;
  request: any;
  commonStorage: string[];
  method: string;
  limitEntries: number;
  onlineMode: boolean;

  constructor() {
    super();
    this.indexedDB = window.indexedDB;
    this.dbVersion = 1;
    this.dbName = "epicDB";
    this.commonStorage = ["schema"];
    this.method = "online";
    this.limitEntries = 1000;
    this.onlineMode = window.navigator.onLine;

    if (this.indexedDB) {
      this.method = "indexed";
    } else {
      Notify.mainMsg(
        "Su navegador no tiene soporte para todas las funciones, se tendrá una experiencia limitada. Se recomienda actualizar su equipo.",
        3
      );
    }
  }

  checkConnection() {
    this.onlineMode = window.navigator.onLine;
  }

  open(upgrade?: (e: any) => void) {
    return new Promise((resolve, reject) => {
      this.request = this.indexedDB.open(this.dbName, this.dbVersion);
      if (upgrade) {
        this.request.onupgradeneeded = (e: any) => {
          upgrade(e.target.result);
        };
      } else {
        this.request.onsuccess = () => {
          this.db = this.request.result;
          resolve(this.db);
        };
      }
    });
  }

  drop() {
    return new Promise((resolve, reject) => {
      if (this.method === "online") {
        resolve("Online method");
      }
      const req = this.indexedDB.deleteDatabase(this.dbName);
      req.onsuccess = function() {
        resolve("Deleted database successfully");
      };
      req.onerror = function() {
        reject("Couldn't delete database");
      };
      req.onblocked = function() {
        reject("Couldn't delete database");
      };
    });
  }

  setStorage(storageName: string) {
    return new Promise((resolve, reject) => {
      if (this.method === "online") {
        resolve("Online method");
      }
      this.open(db => {
        db.createObjectStore(storageName, {
          keyPath: "_id"
        });
        resolve(true);
      });
    });
  }

  setStorageMany(storageNames: string[]) {
    storageNames = storageNames.concat(this.commonStorage);
    return new Promise((resolve, reject) => {
      if (this.method === "online") {
        resolve("Online method");
      }
      this.open(db => {
        storageNames.forEach(storageName => {
          db.createObjectStore(storageName, {
            keyPath: "_id"
          });
        });
        resolve(true);
      });
    });
  }

  async addData(store: string, data: object[]) {
    await this.open();
    return new Promise((resolve, reject) => {
      const transaction = this.db.transaction([store], "readwrite");
      const objectStore = transaction.objectStore(store);
      data.forEach(entry => {
        objectStore.add(entry);
      });
      transaction.oncomplete = () => {
        resolve(data);
      };
    });
  }

  async downloadStorage(schema: string, storeName: string, params?: any) {
    this.checkConnection();
    if (!this.onlineMode) {
      Notify.mainMsg(
        "Error al descargar la información, por favor conéctese a Internet.",
        3
      );
      return false;
    }
    let formatParams = "";
    let aux = 0;
    if (params) {
      formatParams += "?";
      Object.entries(params).forEach(([key, value]) => {
        if (aux >= 1) {
          formatParams += "&";
        }
        formatParams += key;
        formatParams += "=";
        formatParams += value;
        aux++;
      });
    }
    const URL = this.baseURL + "/" + schema + "/" + storeName + formatParams;
    const schemaURL = this.baseURL + "/" + schema + "/" + storeName + "/schema";
    axios.get(schemaURL, { withCredentials: true }).then(shemaResponse => {
      const body = [
        {
          store: storeName,
          data: shemaResponse.data.body,
          _id: IDGenerator(),
          lastUpdate: new Date().getTime()
        }
      ];
      new DB().addData("schema", body);
    });
    const response = await axios.get(URL, { withCredentials: true });
    if (response.data.body.length >= this.limitEntries) {
      let offset;
      if (!params) {
        params = {};
        offset = 1000;
      } else {
        if (params["offset"]) {
          offset = params["offset"] + 1000;
        } else {
          offset = 1000;
        }
      }
      params["offset"] = offset;
      this.downloadStorage(schema, storeName, params);
    }
    return await this.addData(storeName, response.data.body);
  }
}

export default DB;
