<template>
  <div class="rounded card p-3 position-relative card-height-table">
    <table class="table" v-if="1 == 0">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Estándar</th>
          <th scope="col">Valor</th>
          <th scope="col">Cumple</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="standard in standardList" :key="standard._id">
          <th scope="row">{{ standard.count }}</th>
          <td>{{ standard.name }}</td>
          <td>{{ standard.value }}</td>
          <td>NO</td>
        </tr>
      </tbody>
    </table>
    <div
      class="d-flex align-center flex-column"
      style="margin-top: 90px;"
      v-else
    >
      <h1 class="text-center" style="font-size: 5em">
        {{ daysWithoutAccidents }}
      </h1>
      <h6 class="text-center">Días Sin Accidentes</h6>
    </div>
  </div>
</template>

<script>
import Storage from "@/modules/storage";
const store = new Storage();

export default {
  name: "Dashboard Standard",
  data() {
    return {
      standardList: [],
      daysWithoutAccidents: 0
    };
  },
  mounted() {
    store.getData("standard").then(standardList => {
      let aux = 1;
      standardList.sort((a, b) => {
        return a.value > b.value ? -1 : 1;
      });
      standardList.forEach(standard => {
        standard.count = aux;
        aux++;
      });
      this.standardList = standardList;
    });
    store.getData("accident").then(accidentList => {
      let days = 0;
      let JanuaryFirst = "01-01-" + new Date().getFullYear().toString();
      JanuaryFirst = JanuaryFirst.split("-");
      JanuaryFirst = new Date(
        JanuaryFirst[2],
        JanuaryFirst[1] - 1,
        JanuaryFirst[0]
      );
      JanuaryFirst = JanuaryFirst.getTime();
      if (accidentList.length == 0) {
        store.getData("company").then(companies => {
          if (JanuaryFirst > companies[0].registrationDate) {
            days = Date.now() - JanuaryFirst;
          } else {
            days = Date.now() - companies[0].registrationDate;
          }
          days /= 86400000;
          days = Math.floor(days);
          if (days < 0) {
            days = 0;
          }
          this.daysWithoutAccidents = days;
        });
      } else {
        accidentList.sort((a, b) => {
          return a.accidentDate > b.accidentDate ? -1 : 1;
        });
        if (JanuaryFirst > accidentList[0].accidentDate) {
          days = Date.now() - JanuaryFirst;
        } else {
          days = Date.now() - accidentList[0].accidentDate;
        }
        days /= 86400000;
        days = Math.floor(days);
        if (days < 0) {
          days = 0;
        }
        this.daysWithoutAccidents = days;
      }
    });
  }
};
</script>

<style scoped lang="css">
.card-height-table {
  height: 350px;
  overflow: auto;
}
</style>
