<template>
  <div v-if="!hide" id="headerMain" class="position-fixed top-0 start-0">
    <nav class="navbar align-items-center p-0" id="headerTop">
      <div class="container-fluid pe-4">
        <router-link class="navbar-brand p-0" id="logoLink" to="/">
          <img src="/img/white.svg" />
        </router-link>
        <!--
        <form class="d-flex inputIcon">
          <input
            class="form-control me-2 px-2 py-1"
            type="search"
            placeholder="Buscar"
            aria-label="Buscar"
          />
          <svg class="bi" fill="currentColor">
            <use xlink:href="/img/icons/bootstrap-icons.svg#search" />
          </svg>
        </form>
        <svg class="bi" fill="currentColor" id="search">
          <use xlink:href="/img/icons/bootstrap-icons.svg#search" />
        </svg>
        -->
        <svg class="bi" fill="currentColor" id="bell">
          <use xlink:href="/img/icons/bootstrap-icons.svg#bell" />
        </svg>
        <Avatar
          size="32px"
          classProp="rounded"
          @click="hideShowUserOptions"
          id="imageProfile"
          :activeMode="false"
        ></Avatar>
      </div>
    </nav>
    <div id="headerBottom" class="shadow d-flex align-items-center">
      <svg class="bi" fill="currentColor" @click="hideShowMenu">
        <use xlink:href="/img/icons/bootstrap-icons.svg#list" />
      </svg>
      <p class="m-0 pe-4 text-capitalize">{{ currentLocation }}</p>
    </div>
    <div
      id="userOptions"
      class="position-fixed top-0 end-0 mx-4 my-5 text-end p-3 rounded shadow"
    >
      <p class="my-1">Informar un Problema</p>
      <p class="my-1" @click="goToSettings">Ajustes</p>
      <p class="my-1" @click="closeSession">Cerrar Sesión</p>
    </div>
  </div>
</template>

<script>
import Avatar from "@/components/layout/Avatar";
import Session from "@/modules/session";
import Swal from "sweetalert2";

window.addEventListener("click", function(e) {
  try {
    if (document.getElementById("imageProfile").contains(e.target)) {
      return 0;
    } else if (!document.getElementById("userOptions").contains(e.target)) {
      document.getElementById("userOptions").style.display = "none";
    }
  } catch (error) {
    return error;
  }
});

export default {
  data() {
    return {
      navStatus: true,
      currentLocation: ""
    };
  },
  beforeUpdate() {
    setInterval(() => {
      this.currentLocation = localStorage.getItem("currentLocation");
    }, 100);
  },
  name: "Header",
  components: { Avatar },
  methods: {
    closeSession() {
      Swal.fire({
        title: "¿Desea cerra la sesión?",
        showCancelButton: true,
        confirmButtonText: `Sí`,
        cancelButtonText: "No"
      }).then(result => {
        if (result.isConfirmed) {
          Swal.fire({
            icon: "warning",
            title: "Cerrando sesión",
            text: "Estamos haciendo unas comprobaciones...",
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            stopKeydownPropagation: true,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              setInterval(() => {
                const content = Swal.getContent();
                if (content) {
                  const b = content.querySelector("b");
                  if (b) {
                    b.textContent = Swal.getTimerLeft();
                  }
                }
              }, 100);
            }
          });
          const session = new Session();
          session.sigOut();
        }
      });
    },
    goToSettings() {
      this.$router.push({ name: "Settings Basic" });
    },
    hideShowMenu() {
      if (this.navStatus) {
        document.documentElement.style.setProperty("--nav-position", "-300px");
      } else {
        document.documentElement.style.setProperty("--nav-position", "0px");
      }
      this.navStatus = !this.navStatus;
    },
    hideShowUserOptions() {
      const userOptions = document.getElementById("userOptions");
      const computedStyle = window
        .getComputedStyle(userOptions, null)
        .getPropertyValue("display");
      if (computedStyle === "none") {
        document.getElementById("userOptions").style.display = "block";
      } else {
        document.getElementById("userOptions").style.display = "none";
      }
    }
  },
  watch: {
    $route(to, from) {
      if (window.innerWidth <= 750) {
        this.navStatus = true;
        this.hideShowMenu();
      } else if (from.path === "/") {
        this.navStatus = false;
        this.hideShowMenu();
      }
    }
  },
  computed: {
    hide() {
      if (this.$route.path.includes("/employee/profile/edit")) {
        return false;
      }
      return (
        this.$route.path === "/login" ||
        this.$route.path === "/forgotPassword" ||
        this.$route.path === "/signup" ||
        this.$route.path === "/welcome" ||
        this.$route.path === "/404" ||
        this.$route.path === "/loading" ||
        this.$route.path.includes("edit") ||
        this.$route.path.includes("create") ||
        this.$route.path.includes("temp") ||
        this.$route.path.includes("/plain/summary/report") ||
        this.$route.path.includes("/plain/summary/execute") ||
        this.$route.path.includes("/changePassword/")
      );
    }
  }
};
</script>

<style lang="css" scoped>
#userOptions {
  display: none;
  background-color: var(--secondary-bg);
}
#userOptions p {
  cursor: pointer;
}
#logoLink {
  width: 280px;
  display: flex;
  justify-content: center;
}
#headerMain {
  height: var(--header-height);
  width: 100vw;
  z-index: 10;
}
#headerTop {
  background-color: #060818;
  height: 50%;
}
#headerBottom {
  white-space: nowrap;
  overflow-y: auto;
  height: 50%;
  backdrop-filter: blur(4px);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  border-bottom: 2px solid transparent;
  background-clip: border-box;
  color: black;
}
#headerBottom::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  z-index: -1;
  background-color: var(--secondary-bg);
}
#headerTop img {
  height: 32px;
}
#bell {
  color: white;
  height: 20px;
  position: absolute;
  right: 75px;
  width: 20px;
  cursor: pointer;
}
#search {
  color: white;
  height: 20px;
  position: absolute;
  right: 115px;
  width: 20px;
  cursor: pointer;
  display: none;
}
#imageProfile {
  height: 32px;
  width: 32px;
  cursor: pointer;
  overflow: hidden;
}
#imageProfile img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.inputIcon {
  position: absolute;
  left: 320px;
}
.inputIcon input {
  background-color: #212837;
  border: none;
  color: white;
  width: 300px;
}
.inputIcon svg {
  margin-top: 7px;
}
#headerBottom p {
  position: absolute;
  left: 80px;
  color: black;
}
#headerBottom svg {
  height: 50%;
  cursor: pointer;
  width: 50px;
  margin-left: 30px;
}
@media only screen and (max-width: 750px) {
  #logoLink {
    width: auto;
  }
  .inputIcon input {
    display: none;
  }
  .inputIcon svg {
    display: none;
  }
  #search {
    display: block;
  }
  #headerBottom p {
    left: 50px;
  }
  #headerBottom svg {
    width: 30px;
    margin-left: 10px;
  }
}
</style>
