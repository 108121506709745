<template>
  <div class="rounded card p-3 card-height">
    <canvas id="employeeCount"></canvas>
    <h1 class="percent-doughnut text-center">{{ usageEmployee }}%</h1>
    <p class="text-center">
      Su plan actual tiene un límte de
      <strong>{{ limitEmployee }}</strong> empleados
    </p>
  </div>
</template>

<style scoped>
.percent-doughnut {
  font-weight: bold;
  font-size: 4em;
  margin-top: -1em;
}
@media only screen and (max-width: 1400px) {
  .percent-doughnut {
    font-size: 3em;
  }
}
@media only screen and (max-width: 600px) {
  .percent-doughnut {
    font-size: 2em;
  }
}
</style>

<script>
import Chart from "chart.js";
import Storage from "@/modules/storage";

const storage = new Storage();

export default {
  data() {
    return {
      totalEmployee: 0,
      limitEmployee: 0,
      usageEmployee: 0
    };
  },
  async mounted() {
    const planData = await storage.getData(
      "plan",
      localStorage.getItem("subscription.plan")
    );
    const employeeData = await storage.getData("employee");

    this.limitEmployee = planData.restrictions.employees;
    this.totalEmployee = employeeData.length;
    this.usageEmployee = (this.totalEmployee * 100) / this.limitEmployee;
    this.usageEmployee = Math.round(this.usageEmployee * 100) / 100;

    const ctx = document.getElementById("employeeCount").getContext("2d");
    let availableEmployees = this.limitEmployee - this.totalEmployee;
    let mainColor = "rgba(27, 85, 226, 1)";
    if (availableEmployees < 0) {
      availableEmployees = 0;
      mainColor = "rgba(0, 0, 0, 1)";
    } else if (this.usageEmployee > 80) {
      mainColor = "rgba(220, 53, 69, 1)";
    } else if (this.usageEmployee > 60) {
      mainColor = "rgba(253, 126, 20, 1)";
    }

    new Chart(ctx, {
      type: "doughnut",
      data: {
        labels: ["Empleados Registrados", "Disponible"],
        datasets: [
          {
            label: "Empleados",
            data: [this.totalEmployee, availableEmployees],
            backgroundColor: [mainColor, "rgba(233, 236, 239, 1)"]
          }
        ]
      },
      options: {
        rotation: 1 * Math.PI,
        cutoutPercentage: 70,
        circumference: 1 * Math.PI
      }
    });
  }
};
</script>
