<template>
  <div class="d-flex justify-content-center position-relative">
    <img
      v-if="avatarURL"
      v-bind:style="{ height: size, width: size }"
      class="avatarImage"
      :class="[classProp, { extraClass: true }]"
      :src="avatarURL"
      alt="userPhoto"
    />
    <img
      v-else
      v-bind:style="{ height: size, width: size }"
      class="avatarImage"
      :class="[classProp, { extraClass: true }]"
      src="https://storage.assoft.co/img/default/icon_black.svg"
      alt="userPhoto"
    />
    <div
      class="cameraAvatar"
      :class="[classProp, { extraClass: true }]"
      v-bind:style="{ height: size, width: size }"
      @click="changeAvatar"
      @mouseenter="showCamera($event.target)"
      @mouseleave="hideCamera($event.target)"
    >
      <svg
        class="bi position-absolute top-50 start-50 translate-middle"
        fill="currentColor"
      >
        <use xlink:href="/img/icons/bootstrap-icons.svg#camera-fill" />
      </svg>
    </div>
    <input
      @change="getFileClick($event.target.files)"
      type="file"
      name="userProfileAvatar"
      accept=".png,.jpg,.jpeg,.gif,.webp"
      id="userProfileAvatar"
      hidden
    />
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Storage from "@/modules/storage";

export default {
  name: "Avatar",
  props: ["size", "classProp", "activeMode"],
  data() {
    return {
      avatarURL: false
    };
  },
  mounted() {
    if (localStorage.getItem("user.avatarURL") != "null") {
      this.avatarURL =
        localStorage.getItem("user.avatarURL") +
        "?seed=" +
        Math.floor(Math.random() * 10000000);
    }
  },
  methods: {
    showCamera(div) {
      if (this.activeMode) {
        div.style.opacity = "1";
      }
    },
    hideCamera(div) {
      if (this.activeMode) {
        div.style.opacity = "0";
      }
    },
    changeAvatar() {
      if (this.activeMode) {
        document.getElementById("userProfileAvatar").click();
      }
    },
    getFileClick(files) {
      if (files.length === 0) {
        return true;
      }

      Swal.fire({
        icon: "warning",
        title: "Actualizando imagen de perfil",
        text: "Estamos actualizando la imagen de perfil",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        stopKeydownPropagation: true
      });

      if (files[0].type.startsWith("image/")) {
        const avatarFile = new FormData();
        const URL =
          new Storage().baseURL +
          "/auth/user/" +
          localStorage.getItem("user._id") +
          "/upload";
        avatarFile.append("avatar", files[0]);

        axios
          .post(URL, avatarFile, { withCredentials: true })
          .then(res => {
            localStorage.setItem("user.avatarURL", res.data.body[0].avatarURL);
            this.avatarURL = res.data.body[0].avatarURL;
            location.reload();
          })
          .catch(() => {
            Swal.fire({
              icon: "error",
              title: "No es posible realizar esta acción",
              text:
                "Para cambiar la foto de perfil es necesario conexión a Internet"
            });
          });
      } else {
        Swal.fire({
          icon: "error",
          title: "Tipo de archivo no permitido",
          toast: true,
          position: "bottom-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
      }
    }
  }
};
</script>

<style lang="css" scoped>
.avatarImage {
  cursor: pointer;
  object-fit: cover;
}
.cameraAvatar {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.664);
  position: absolute;
  opacity: 0;
  transition: 0.3s ease-in-out;
}
.cameraAvatar svg {
  color: white;
  width: 50%;
}
</style>
