<template>
  <div class="rounded card p-3">
    <h6 class="text-primary fw-bold">¿Necesita más espacio?</h6>
    <p>
      Para obtener más espacio en nuestro servidor deberá actualizar su plan o
      <a href="">contactarnos</a> para un almacenamiento personalizado.
    </p>
    <router-link class="d-grid gap-2" to="/subscription">
      <button type="button" class="btn btn-primary">Cambiar Plan</button>
    </router-link>
  </div>
</template>
