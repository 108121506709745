export default [
  {
    path: "/user",
    name: "User",
    component: () => import("../views/user/User.vue")
  },
  {
    path: "/user/create/:_id",
    name: "User Create",
    component: () => import("../views/user/Create.vue")
  }
];
