<template>
  <div class="rounded card p-3 position-relative card-height">
    <div class="position-absolute top-50 start-50 translate-middle card-width">
      <p>
        <strong>{{ totalSize }} MB</strong> de
        <strong>{{ limitSize }} MB</strong> utilizadas
      </p>
      <div class="progress">
        <div
          class="progress-bar"
          role="progressbar"
          :style="'width: ' + usageSize + '%'"
          :aria-valuenow="usageSize"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <div class="d-flex flex-row mt-3">
        <div class="point-primary"></div>
        <p class="m-0">Utilizado</p>
        <div class="point-primary point-semi"></div>
        <p class="m-0">Libre</p>
      </div>
    </div>
  </div>
</template>

<script>
import Storage from "@/modules/storage";

const storage = new Storage();

export default {
  name: "Dashboard Storage",
  data() {
    return {
      totalSize: 0,
      limitSize: 0,
      usageSize: 0
    };
  },
  async mounted() {
    const planData = await storage.getData(
      "plan",
      localStorage.getItem("subscription.plan")
    );
    const fileData = await storage.getData("file");

    this.limitSize = planData.restrictions.storage;

    fileData.forEach(file => {
      this.totalSize += file.size;
    });

    this.limitSize = this.limitSize / 1000000;
    this.limitSize = Math.round(this.limitSize * 100) / 100;

    this.totalSize = this.totalSize / 1000000;
    this.totalSize = Math.round(this.totalSize * 100) / 100;

    this.usageSize = (this.totalSize * 100) / this.limitSize;
    this.usageSize = Math.round(this.usageSize * 100) / 100;
  }
};
</script>

<style lang="css">
.card-height {
  height: 100%;
  min-height: 150px;
  max-height: 350px;
}
.card-width {
  width: 94%;
}
.point-primary {
  height: 18px;
  width: 18px;
  background-color: var(--bs-primary);
  border-radius: 50%;
  margin-right: 5px;
}
.point-semi {
  background-color: #e9ecef;
  margin-left: 25px;
}
</style>
