export default [
  {
    path: "/document/main",
    name: "Document Main",
    component: () => import("../views/document/Main.vue")
  },
  {
    path: "/document/main/:_id",
    name: "Document Main Branch",
    component: () => import("../views/document/MainBranch.vue")
  },
  {
    path: "/document/main/:_id/:fileId",
    name: "Document Main Branch File",
    component: () => import("../views/document/MainBranch.vue")
  },
  {
    path: "/document/main/create/:_id/:fileId",
    name: "Document Main Branch File Create",
    component: () => import("../views/document/Create.vue")
  },
  {
    path: "/document/main/edit/:_id/:fileId",
    name: "Document Main Branch File Edit",
    component: () => import("../views/document/Edit.vue")
  },
  {
    path: "/document/retention",
    name: "Document Retention",
    component: () => import("../views/document/Retention.vue")
  },
  {
    path: "/document/retention/:_id",
    name: "Document Retention Branch",
    component: () => import("../views/document/RetentionBranch.vue")
  },
  {
    path: "/document/retention/create/:_id",
    name: "Document Retention Branch File Create",
    component: () => import("../views/document/RetentionCreate.vue")
  },
  {
    path: "/document/inactive",
    name: "Document Inactive",
    component: () => import("../views/document/Inactive.vue")
  },
  {
    path: "/document/inactive/:_id",
    name: "Document Inactive Branch",
    component: () => import("../views/document/InactiveBranch.vue")
  },
  {
    path: "/document/inactive/:_id/:year",
    name: "Document Inactive Year",
    component: () => import("../views/document/InactiveYear.vue")
  },
  {
    path: "/document/inactive/:_id/:year/:folderId",
    name: "Document Inactive Folder",
    component: () => import("../views/document/InactiveFolder.vue")
  }
];
