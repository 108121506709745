export default [
  {
    path: "/branch",
    name: "Branch",
    component: () => import("../views/branch/Branch.vue")
  },
  {
    path: "/branch/create",
    name: "Branch Create",
    component: () => import("../views/branch/Create.vue")
  },
  {
    path: "/branch/edit/:_id",
    name: "Branch Edit",
    component: () => import("../views/branch/Edit.vue")
  }
];
