export default [
  {
    path: "/employee/workArea",
    name: "Work Area",
    component: () => import("../views/workArea/WorkArea.vue")
  },
  {
    path: "/employee/workArea/:_id",
    name: "Work Area Branch",
    component: () => import("../views/workArea/WorkAreaBranch.vue")
  },
  {
    path: "/employee/workArea/create/:_id",
    name: "Work Area Create",
    component: () => import("../views/workArea/Create.vue")
  },
  {
    path: "/employee/workArea/edit/:_id",
    name: "Work Area Edit",
    component: () => import("../views/workArea/Edit.vue")
  }
];
