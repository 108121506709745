export default [
  {
    path: "/plain/target",
    name: "Plain Target",
    component: () => import("../views/plain/Target.vue")
  },
  {
    path: "/plain/target/:_id",
    name: "Plain Target Branch",
    component: () => import("../views/plain/TargetBranch.vue")
  },
  {
    path: "/plain/target/create/:_id",
    name: "Plain Target Create",
    component: () => import("../views/plain/Create.vue")
  },
  {
    path: "/plain/target/edit/:_id",
    name: "Plain Target Edit",
    component: () => import("../views/plain/Edit.vue")
  },
  {
    path: "/plain/summary",
    name: "Plain Summary",
    component: () => import("../views/plain/Summary.vue")
  },
  {
    path: "/plain/summary/:_id",
    name: "Plain Summary Branch",
    component: () => import("../views/plain/SummaryBranch.vue")
  },
  {
    path: "/plain/summary/create/:_id",
    name: "Plain Summary Create",
    component: () => import("../views/plain/SummaryCreate.vue")
  },
  {
    path: "/plain/summary/edit/:_id",
    name: "Plain Summary Edit",
    component: () => import("../views/plain/SummaryEdit.vue")
  },
  {
    path: "/plain/summary/execute/:_id/:branchId",
    name: "Plain Summary Execute",
    component: () => import("../views/plain/SummaryExecute.vue")
  },
  {
    path: "/plain/summary/temp",
    name: "Plain Summary Temp",
    component: () => import("../views/plain/SummaryReportv3.vue")
  }
];
